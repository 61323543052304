<template>
  <div class="home-main-wrap">
    <img src="@images/home/logo.png" style="display: none" />
    <header-app-tips
      class="app_tips"
      :text="downLoadLoadText"
      v-if="isDownLoadLoad == 1"
    />
    <div class="scroll-main" @scroll="scroll_outer">
      <div
        :class="[
          'home_navbar',
          bannerData && bannerData.length > 0 ? '' : 'no_banner',
        ]"
        :style="{ width: contentWidth }"
      >
        <div class="navbar">
          <div class="navbar_left">
            <template v-if="isInApp && !isAppHomePage">
              <img
                class="goback"
                :src="require('@images/home/fffBack.svg')"
                alt=""
                @click="goBack"
              />
            </template>
            <div v-else-if="!isInApp" @click="goPersonCenter">
              <img
                v-if="userInfos && userInfos.img"
                :src="userInfos.img + '?x-oss-process=image/resize,w_100'"
                class="login_img"
                alt
              />
              <img v-else src="@images/home/my_photo.png" alt />
              <span class="new-tip" v-if="isLogin && unreadCount">
                {{ unreadCount }}
              </span>
            </div>
          </div>
          <div class="navbar_center">
            {{ isMain ? collegeText : "心血管远程教育学院" }}
          </div>
          <div
            class="navbar_right"
            @click="toSubjectFocus"
            v-if="isMain && !isInApp"
          >
            切换学科
            <img src="@images/home/rightDown.png" alt />
          </div>
          <div class="navbar_right" v-else></div>
        </div>
        <div class="search" ref="search" v-if="!isSticky">
          <div class="search_left" @click="handleSearch">
            <img src="@images/home/homeSearch.png" alt />
            <span class="search_text van-ellipsis"
              >专家姓名、课程名称、疾病名称、会议名称等</span
            >
          </div>
        </div>
        <div
          class="own_sticky"
          v-else
          :style="{
            width: contentWidth,
            top: tabOffsetTop,
          }"
        >
          <div class="sticky_search">
            <div class="sticky_left" @click="goPersonCenter" v-if="!isInApp">
              <img
                v-if="userInfos && userInfos.img"
                :src="userInfos.img + '?x-oss-process=image/resize,w_100'"
                class="login_img"
                alt
              />
              <img v-else src="@images/home/my_photo.png" alt />
              <span class="new-tip" v-if="isLogin && unreadCount">
                {{ unreadCount }}
              </span>
            </div>
            <div class="sticky_center" @click="handleSearch">
              <img src="@images/home/homeSearch.png" alt />
              <span class="search_text van-ellipsis"
                >专家姓名、课程名称、疾病名称、会议名称等</span
              >
            </div>
            <div
              class="sticky_right"
              v-if="isMain && !isInApp"
              @click="toSubjectFocus"
            >
              {{ collegeText }}
              <img src="@images/home/rightDown.png" alt />
            </div>
            <div
              class="sticky_right"
              v-else-if="!isAppHomePage"
              @click="goBack"
            >
              返回
            </div>
          </div>
          <!-- <disease-tab
                        :isSticky="!isSticky"
                        v-if="!showPopupBox"
                        :diseaseActive="diseaseActive"
                        @changeSelectedDisease="changeSelectedDisease"
                        @diseaseMore="diseaseMore"
                    ></disease-tab> -->
        </div>
        <!-- 处理谷歌浏览器抖动 -->
        <div
          class="own_sticky_visibility"
          v-if="isSticky"
          :style="{ height: visibilityHeight }"
        ></div>
        <!-- <van-sticky :offset-top="fixedTop"> -->
        <!-- 疾病导航 -->
        <!-- <disease-tab
                    v-if="!isSticky"
                    :diseaseActive="diseaseActive"
                    @changeSelectedDisease="changeSelectedDisease"
                    @diseaseMore="diseaseMore"
                ></disease-tab> -->
        <!-- </van-sticky> -->
        <div class="banner">
          <carousel
            :data="bannerData"
            @itemClick="bannerClick"
            v-if="bannerData && bannerData.length > 1"
          ></carousel>
          <div
            v-if="bannerData.length > 0 && bannerData.length <= 1"
            class="swiper-one"
          >
            <p></p>
            <div class="swiper-one-img">
              <img
                @click="bannerClick(0)"
                :src="bannerData[0].img + '?x-oss-process=image/resize,w_750'"
                alt
              />
            </div>
            <p></p>
          </div>
        </div>
        <div v-if="!(bannerData && bannerData.length > 0)">
          <loading v-if="tabLoading" class="loading"></loading>
          <swiper
            class="menu-swiper"
            :options="swiperOption"
            v-if="tabs && tabs.length && !tabLoading"
          >
            <swiper-slide
              v-for="(tabs, index) in menuDataContainer"
              :key="index"
            >
              <div class="menu_tab">
                <div
                  class="menu_item"
                  v-for="(item, index) in tabs"
                  :key="index"
                  @click="menuItemClick(item)"
                >
                  <div class="menu_logo">
                    <img
                      :src="item.logo + '?x-oss-process=image/resize,w_120'"
                      alt
                    />
                  </div>
                  <span>{{ item.name }}</span>
                  <div class="new_tip" v-if="item.icon_status">
                    <img src="@images/home/new.png" alt />
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div
              class="swiper-pagination"
              slot="pagination"
              v-if="menuDataContainer.length > 1"
            ></div>
          </swiper>
        </div>
      </div>
      <div v-if="bannerData && bannerData.length > 0">
        <loading v-if="tabLoading" class="loading"></loading>
        <swiper
          class="menu-swiper"
          :options="swiperOption"
          v-if="tabs && tabs.length && !tabLoading"
        >
          <swiper-slide v-for="(tabs, index) in menuDataContainer" :key="index">
            <div class="menu_tab">
              <div
                class="menu_item"
                v-for="(item, index) in tabs"
                :key="index"
                @click="menuItemClick(item)"
              >
                <div class="menu_logo">
                  <img
                    :src="item.logo + '?x-oss-process=image/resize,w_120'"
                    alt
                  />
                </div>
                <span>{{ item.name }}</span>
                <div class="new_tip" v-if="item.icon_status">
                  <img src="@images/home/new.png" alt />
                </div>
              </div>
            </div>
          </swiper-slide>
          <div
            class="swiper-pagination"
            slot="pagination"
            v-if="menuDataContainer.length > 1"
          ></div>
        </swiper>
      </div>
      <!-- 专家列表 -->
      <div class="HomeExpertList" v-if="expertList && expertList.length">
        <div class="expert-title">
          <span>专家主页</span>
          <span @click="handleExpert">
            全部
            <van-icon name="arrow" />
          </span>
        </div>
        <loading v-show="markLoading" class="loading"></loading>
        <home-expert-list
          v-show="!markLoading"
          @handleClick="handleClick"
          :expertList="expertList"
          :expertLoading="expertLoading"
          @leftLoad="getExpertlist(true)"
          ref="homeExpertList"
        ></home-expert-list>
      </div>

      <div class="home-main-content">
        <template v-if="collegeID == 170">
          <!-- 资讯 -->
          <consulting-item
            v-if="informationList && informationList.length"
            ref="homeConsulting"
            :data="informationList"
            @handleClick="menuClick"
          ></consulting-item>
          <!-- 大会 -->
          <advance-item
            v-if="meetingList && meetingList.length"
            ref="homeAdvance"
            :data="meetingList"
            @handleClick="menuClick"
          ></advance-item>
        </template>
        <template v-else>
          <!-- 大会 -->
          <advance-item
            v-if="meetingList && meetingList.length"
            ref="homeAdvance"
            :data="meetingList"
            @handleClick="menuClick"
          ></advance-item>
          <!-- 资讯 -->
          <consulting-item
            v-if="informationList && informationList.length"
            ref="homeConsulting"
            :data="informationList"
            @handleClick="menuClick"
          ></consulting-item>
        </template>
        <!-- 手术 -->
        <operation-item
          v-if="surgicalList && surgicalList.length"
          ref="homeOperation"
          :data="surgicalList"
          @handleClick="menuClick"
        ></operation-item>
        <course-item
          v-if="productionList && productionList.length"
          ref="homeCourse"
          :data="productionList"
          @handleClick="menuClick"
        ></course-item>

        <!-- </div> -->
      </div>
      <!-- <login :show="showLogin" @handleClose="handleLoginClose"></login> -->
      <!-- <div
                @click="joinMeeting"
                class="join-meeting"
                v-if="isInApp"
                :style="{
                    right: ispc ? contentLeft : isScroll ? '-1.2rem' : '',
                    opacity: isScroll ? '0.3' : ''
                }"
            >
                <img src="@images/home/joinMeeting.gif" />
            </div> -->
      <div
        @click="back_to_top"
        class="scroll_to_top"
        v-if="show_scroll_to_top"
        :style="{ right: ispc ? contentLeft : '' }"
      >
        <img src="@images/common/top.png" />
      </div>
    </div>
    <!-- 科室 -->
    <van-popup
      v-model="showPopupBox"
      position="top"
      :closeable="false"
      :style="{ width: contentWidth, left: contentLeft }"
      @click-overlay="showPopupBox = false"
      @click-close-icon="showSelectChannel = false"
    >
      <select-popup
        @goPersonCenter="goPersonCenter"
        @changePopupCollege="changeSelectedCollege"
        @closed="showPopupBox = false"
      ></select-popup>
    </van-popup>
    <!-- 疾病 -->
    <!-- <van-popup
            v-model="showSelectChannel"
            position="bottom"
            class="popup-box"
            closeable
            :style="{ width: contentWidth, left: contentLeft }"
            @closed="showSelectChannel = false"
            @click-close-icon="showSelectChannel = false"
        >
            <select-channel @close="changeSelectedDisease"></select-channel>
        </van-popup> -->
    <!-- 剪切板登录 -->
    <!-- <van-popup
            v-model="showClipboardOneLogin"
            position="bottom"
            class="clipboard-one-login"
            closeable
            round
            :style="{ width: contentWidth, left: contentLeft }"
            @closed="showClipboardOneLogin = false"
            @click-close-icon="showClipboardOneLogin = false"
        >
            <clipboard-one-login
                :data="clipboardLoginInfo"
                @submit="clipboardCodeLogin"
            ></clipboard-one-login>
        </van-popup> -->
    <!-- <message-push-dialog
            :data="pushMessage"
            :show="isPushMessage"
            v-if="isPushMessage"
            @dialogClose="isPushMessage = false"
            @handleViewNow="handleViewNow"
        ></message-push-dialog> -->
    <subject-select-list
      @cancel="isSubjectShow = false"
      :show="isSubjectShow"
      :data="subjectData"
    ></subject-select-list>

    <!-- <div class="backlog-container" v-if="showBacklogDialog">
            <img
                src="@images/backlog/dialog-close.svg"
                alt=""
                @click.stop="showBacklogDialog = false"
            />
            <span>提示：您有 {{ backlogCount }} 条待办任务</span>
            <div class="handle-button" @click="handleBacklog">
                <span>立即处理</span>
            </div>
        </div> -->
  </div>
</template>

<script>
import javaRequest from '@utils/request/javaRequest'
import { mapGetters, mapState } from '@utils/vuex'
import Request from '@request'
import api from '@api'
import Loading from '@components/Loading'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import HomeExpertList from '@components/Expert/HomeExpertList'
import Carousel from '@components/Common/Carousel'
import Navbar from '@components/Common/Navbar'
import HeaderAppTips from '@components/Common/HeaderAppTips'
import AdvanceItem from './components/Home/AdvanceItem'
import CourseItem from './components/Home/CourseItem'
import ConsultingItem from './components/Home/ConsultingItem'
import OperationItem from './components/Home/OperationItem'
import DiseaseTab from './components/Home/DiseaseTab'
import MessagePushDialog from './components/Home/MessagePushDialog'
import ClipboardOneLogin from '@components/Login/ClipboardOneLogin'
import SelectPopup from './components/SelectPopup'
import SelectChannel from './components/SelectChannel'
import SubjectSelectList from './components/SubjectSelectList'
import {
  NativeMessagePush,
  NativeMessageData,
  NativeMessageNotice,
  NativeMessageResourceCache
} from '@utils/nativeBridge/nativeMessage'
import os from '@utils/os'
import utils from '@utils'
export default {
  name: 'home',
  components: {
    SelectPopup,
    SelectChannel,
    SubjectSelectList,
    Loading,
    // Login,
    AdvanceItem,
    ConsultingItem,
    CourseItem,
    OperationItem,
    DiseaseTab,
    Swiper,
    SwiperSlide,
    Carousel,
    Navbar,
    HeaderAppTips,
    HomeExpertList,
    MessagePushDialog,
    ClipboardOneLogin
  },
  data () {
    return {
      AppTipsHeight: 0,
      surgicalList: [],
      meetingList: [],
      informationList: [],
      productionList: [],
      collegeText: '全部学科',
      tabs: [],
      tabLoading: true,
      markLoading: true,
      show_scroll_to_top: false,
      expertLoading: false,
      // showLogin: false,
      isSticky: false,
      searchTop: 0,
      swiperOption: {
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination'
        }
      },
      expertList: [],
      loginType: '',
      expertPage: 1,
      type_id: 0,
      isScroll: false,
      tabActive: '',
      diseaseActive: 0,
      // showSelectChannel: false,
      showSelectPopup: false,
      showPopupBox: false,
      isPushMessage: false,
      pushMessage: {},
      isSubjectShow: false,
      subjectData: [],
      showClipboardOneLogin: false,
      clipboardLoginInfo: {},
      showBacklogDialog: false,
      backlogCount: 0,
      isDownLoadLoad: 1,
      downLoadLoadText: ''
    }
  },
  computed: {
    visibilityHeight () {
      let height = 0
      if (this.diseaselist && this.diseaselist.length) {
        height = `${2.01 + 1.42}rem`
      } else {
        height = '2.01rem'
      }
      return height
    },
    stickyBg () {
      if (this.isSticky) {
        return '#0089ff'
      }
    },
    userInfos () {
      return this.$store.state.user.userInfos
    },
    ...mapState('home', [
      'allCollege',
      'collegeID',
      'bannerData',
      'diseaseID',
      'diseaselist',
      'isCollegeClose',
      'consulting_data'
    ]),
    ...mapState('messagePush', ['unreadCount']),
    ...mapGetters([
      'isInApp',
      'showHeaderAppTips',
      'isMain',
      'isLogin',
      'userIdentity',
      'contentWidth',
      'contentLeft',
      'userInfo',
      'isAppHomePage'
    ]),
    ispc () {
      return os.isPC() ? true : false
    },
    tabOffsetTop () {
      if (this.isDownLoadLoad == 1) {
        return !this.showHeaderAppTips ? '0' : '1.45rem'
      } else {
        return 0
      }

    },
    fixedTop () {
      return !this.showHeaderAppTips ? '1.7rem' : '3.48rem'
    },
    menuDataContainer () {
      return this.tabs.reduce((total, item, index) => {
        if (index % 10 === 0) total.push([])
        let container = total[total.length - 1]
        container.push(item)
        return total
      }, [])
    }
  },
  activated () {
    if (this.isMain) {
      document.title = '医谱'
      if (/release-/.test(location.href)) {
        document.title = ''
      }
    }
    this.getSeoconfig()
    // if (this.isInApp) {
    //     this.getisDeptUnion()
    //     this.insertEquipmentRecord()
    // }
    this.$hideLoading()
    // this.getMenuicon()
    this.$store.commit('clearKeepAlive', [])
    if (this.$store.state.common.homeScrollTop) {
      document.querySelector('.scroll-main').scrollTop =
        this.$store.state.common.homeScrollTop || 0
    }
    this.$refs.homeExpertList.addScroll()
  },
  deactivated () {
    const head = document.querySelector('head')
    head.removeChild(document.getElementById('description'))
    head.removeChild(document.getElementById('metaKeyword'))
    this.$refs.homeExpertList.removeSrcoll()
  },
  async created () {
    this.$hideLoading()
    if (this.isMain) {
      document.title = '医谱 - 专业的医生学术交流平台'
      if (/release-/.test(location.href)) {
        document.title = ''
      }
    } else {
         document.title = '专业的医生学术交流平台'
    }
    console.log(this.collegeID)
    this.$store.commit('home/setCollegeID', this.$route.query.college_id || this.collegeID)
    this.$store.commit('home/setDiseaseID', this.$route.query.disease_id || 0)
    this.diseaseActive = this.$route.query.disease_id
    this.collegeText = this.$route.query.collegeName || '全部学科'
    // this.$store.commit('setTabbarSelectedIndex', 0)
    // this.getMenuicon()
    this.getData()
    this.getSeoconfig()
    // if (this.isInApp) {
    //     this.checkClipboardCode()
    //     if (this.isLogin) {
    //         this.getBacklogCount()
    //     }
    //     // this.$nativeBridge.sendMessage(
    //     //     new NativeMessageResourceCache(
    //     //         'https://iv.docbook.com.cn/video/2023/01/11/63bed223b7c431673450019kelianti.mp4'
    //     //     )
    //     // )
    // }
  },
  mounted () {
    this.getMessagePush()
    // if (this.isInApp) {
    //     this.getisDeptUnion()
    //     this.insertEquipmentRecord()
    // }

    // console.log(this.$store.getters['user/userID'])
  },
  beforeDestroy () {
    const head = document.querySelector('head')
    head.removeChild(document.getElementById('description'))
    head.removeChild(document.getElementById('metaKeyword'))
    this.$refs.homeExpertList.removeSrcoll()
  },
  methods: {
    // 获取seo 配置
    getSeoconfig () {
      javaRequest.get(api.common.seoconfig, { siteType: 1 }, false).then(res => {
        const seo = res.result[0]
        const head = document.querySelector('head')
        const meta = document.createElement('meta')
        const metaKeyword = document.createElement('meta')
        meta.name = 'description'
        meta.content = seo.seoDesc
        meta.id = 'description'
        metaKeyword.name = 'keyword'
        metaKeyword.content = seo.keyword
        metaKeyword.id = 'metaKeyword'
        if (this.isMain) {
            document.title = seo.title
        }
        else {
            document.title = '专业的医生学术交流平台'
        }
        head.appendChild(meta)
        head.appendChild(metaKeyword)
      })
    },
    // 获取科联体未读消息数
    // getisDeptUnion() {
    //     if (this.isLogin) {
    //         this.$store
    //             .dispatch('getisDeptUnion', {
    //                 doctorId: this.userInfos.doctor_id
    //             })
    //             .then(res => {
    //                 if (res.result.total) {
    //                     this.$store.commit('setdeptUnionId', res.result.records[0].id)
    //                     this.$store.dispatch('messagePush/getUnreadQuantity', {
    //                         doctorId: this.userInfos.doctor_id,
    //                         deptUnionId: res.result.records[0].id
    //                     })
    //                 }
    //             })
    //     }
    // },
    // 获取 code 并检查是否合法
    // checkClipboardCode() {
    //     let code = this.$nativeBridge.sendMessage(
    //         new NativeMessageData({
    //             type: 'getClipboardContent'
    //         })
    //     )

    //     if (os.isIOS() && /:\//.test(code)) code = code.match(/:\/(\S+)/)[1]
    //     if (code) {
    //         this.$store.dispatch('deptAssociation/getShearPlateInfo', { code }).then(res => {
    //             const { userid } = res
    //             const currentUserid = localStorage.getItem('userid')
    //             if (userid != currentUserid) {
    //                 this.clipboardLoginInfo = { ...res, code }
    //                 this.showClipboardOneLogin = true
    //             }
    //         })
    //     }
    // },
    // 剪切板自动登录
    // clipboardCodeLogin(info) {
    //     const { code } = info
    //     this.$store.dispatch('deptAssociation/shearPlateLogin', { code }).then(res => {
    //         this.$nativeBridge.sendMessage(
    //             new NativeMessageNotice({
    //                 type: 'clearClipboardContent'
    //             })
    //         )
    //         window.location.reload()
    //     })
    // },
    // app后台运行接口，误删
    appRunninginTheBackground () {
      window.location.reload()
    },
    // 保存设备id
    // insertEquipmentRecord() {
    //     let systemInfo = this.$nativeBridge.sendMessage(
    //         new NativeMessageData({
    //             type: 'systemInfo'
    //         })
    //     )
    //     let device_token = ''
    //     let notification_limit = ''
    //     if (os.isAndroid()) {
    //         device_token = JSON.parse(systemInfo).device_token
    //         notification_limit = JSON.parse(systemInfo).notification_limit
    //     }
    //     if (os.isIOS()) {
    //         device_token = JSON.parse(JSON.parse(systemInfo).systemInfo).device_token
    //         notification_limit = JSON.parse(
    //             JSON.parse(systemInfo).systemInfo
    //         ).notification_limit
    //     }
    //     this.$store.commit('messagePush/setNotificationLimit', notification_limit)
    //     console.log(JSON.parse(systemInfo), '获取设备id')
    //     if (device_token) {
    //         console.log(
    //             {
    //                 equipmentCode: device_token,
    //                 userType: this.isLogin ? 0 : 1,
    //                 equipmentType: os.getDevice() == 'ios' ? 1 : 0
    //             },
    //             '-------保存设备id参数'
    //         )
    //         this.$store
    //             .dispatch('messagePush/insertEquipmentRecord', {
    //                 equipmentCode: device_token,
    //                 userType: this.isLogin ? 0 : 1,
    //                 equipmentType: os.getDevice() == 'ios' ? 1 : 0
    //             })
    //             .then(res => {
    //                 console.log(res, '测试请求')
    //             })
    //     }
    // },
    // 推送弹框
    getMessagePush () {
      this.$store
        .dispatch('messagePush/getMessagePush', {
          pageNum: 1,
          pageSize: 20,
          messageRecordStatus: 0
        })
        .then(res => {
          this.isPushMessage = res.result.yiPuMessageRecordEntity
          if (this.isPushMessage) this.pushMessage = res.result.yiPuMessageRecordEntity
        })
    },
    // 跳转内容类型:0=三方链接、1=富文本内容、2=单场会、3=框架会、4=分会场、5=精品课程（单个课程）、6=精品课程（系列课程）、7=医学资讯、8=手术示教、9=医生主页
    // handleViewNow(data) {
    //     if (data.messageRecordStatus === 0) {
    //         this.$store
    //             .dispatch('messagePush/updateMessageRecord', {
    //                 messageRecordId: data.id,
    //                 pushMessageId: data.pushMessageId,
    //                 messageRecordStatus: 1
    //             })
    //             .then(res => {
    //                 this.getMessagePush()
    //                 if (!data.pushJumpContent) {
    //                     if (this.isInApp) {
    //                         this.$nativeBridge.sendMessage(
    //                             new NativeMessagePush({
    //                                 pageName: 'newWeb',
    //                                 params: {
    //                                     url: data.pushJumpUrl
    //                                 }
    //                             })
    //                         )
    //                     } else {
    //                         location.href = data.pushJumpUrl
    //                     }
    //                 } else {
    //                     this.$router.push(JSON.parse(data.pushJumpUrl))
    //                 }
    //             })
    //     }
    // },
    // // 疾病弹框
    // diseaseMore() {
    //     this.showSelectChannel = true
    // },
    // 菜单跳转
    menuItemClick (data) {
      if (data.content) {
        // if (this.isInApp) {
        //     if (
        //         /release-m.docbook.com.cn/.test(data.content) ||
        //         /m.docbook.com.cn/.test(data.content)
        //     ) {
        //         let urlAry = data.content.split('/')
        //         let isHome = data.content.split('/')[urlAry.length - 1].split('?')[0]
        //         let url = !isHome
        //             ? `${data.content.split('/')[urlAry.length - 1]}&isHome=1`
        //             : `${data.content.split('/')[urlAry.length - 1]}`

        //         if (!isHome) {
        //             window.history.replaceState('', '', url)

        //             this.$store.commit(
        //                 'home/setCollegeID',
        //                 utils.getUrlParam(data.content, 'college_id')
        //             )
        //             this.$store.commit('home/setDiseaseID', 0)
        //             this.diseaseActive = 0
        //             this.getData('College')
        //             this.expertPage = 1
        //             this.$nextTick(() => {
        //                 this.$forceUpdate()
        //             })
        //         } else {
        //             this.$router.push({
        //                 path: url
        //             })
        //         }
        //     } else {
        //         this.$nativeBridge.sendMessage(
        //             new NativeMessagePush({
        //                 pageName: 'newWeb',
        //                 params: {
        //                     url: data.content
        //                 }
        //             })
        //         )
        //     }
        // } else {
        window.location.href = data.content
        // }
        //
      } else if (data.type == 8) {
        if (data.college_data.length > 1) {
          // debugger
          this.isSubjectShow = true
          this.subjectData = data.college_data
        } else {
          this.$router.push({
            path: '/encyclopediasHome',
            query: {
              college_id: data.college_data[0].college_id,
              college_name: data.college_data[0].college_name
            }
          })
        }
      } else if (data.type == 9) {
        this.$router.push({
          path: '/departmentHomePage',
          query: {
            type: data.type,
            name: this.collegeText,
            userid: this.$store.getters.userID || '',
            college_id: this.collegeID
          }
        })
      } else {
        this.$store.commit('setKeepAlive', ['home'])
        this.$store.commit(
          'setHomeScrollTop',
          document.querySelector('.scroll-main').scrollTop
        )
        this.$router.push({
          path: '/channelClass',
          query: {
            type: data.type,
            name: data.name,
            college_id: this.collegeID,
            disease_id: this.diseaseID
          }
        })
      }
    },
    // 更多跳转
    menuClick (type) {
      let name = ''
      this.tabs.map(item => {
        if (type == item.type) {
          name = item.name
        }
      })

      console.log(name, '--------')
      this.$store.commit('setHomeScrollTop', document.querySelector('.scroll-main').scrollTop)
      this.$store.commit('setKeepAlive', ['home'])
      this.$router.push({
        path: '/channelClass',
        query: {
          type: type,
          name: name,
          college_id: this.collegeID,
          disease_id: this.diseaseID
        }
      })
    },
    scroll_outer (e) {
      let scrollTop = e.target.scrollTop
      if (!this.searchTop) {
        this.searchTop = document.querySelector('.search').getBoundingClientRect().top
      }

      if (scrollTop >= this.searchTop - this.AppTipsHeight) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
      this.$nextTick(function () {
        if (scrollTop > window.screen.availHeight) {
          this.show_scroll_to_top = true
        } else {
          this.show_scroll_to_top = false
        }
      })
      let scroll = scrollTop - this.isScrollTop
      this.isScrollTop = scrollTop
      if (Math.abs(scroll) < 5) return false
      if (scroll < 0) {
        this.isScroll = false
      } else {
        this.isScroll = true
      }
    },
    back_to_top () {
      document.querySelector('.scroll-main').scrollTop = 0
      this.show_scroll_to_top = false
    },
    getExpertlist (flag) {
      this.expertLoading = true
      this.markLoading = true
      if (!flag) {
        this.expertList = []
      }
      let param = {
        college: this.collegeID || 0,
        disease_id: this.diseaseID || 0,
        page: this.expertPage
      }
      return new Promise((resolve, reject) => {
        Request.post(api.home.expertlist, param)
          .then(res => {
            if (flag) {
              this.expertList = [...this.expertList, ...res.data.data]
            } else {
              this.expertList = res.data.data
            }
            this.expertLoading =
              res.data.data.length == 0 || res.data.data.length < 20 ? true : false
            this.expertPage++
            this.markLoading = false
            resolve()
          })
          .catch(err => {
            this.expertLoading = true
            this.markLoading = false
            reject()
          })
      })
    },
    goPersonCenter () {
      if (this.isLogin) {
        this.$store.commit('setHomeScrollTop', 0)
        this.$store.commit('clearKeepAlive', [])
        this.$router.push({
          path: '/personalCenter'
        })
      } else {
        // this.loginType = 'personal'
        // this.showLogin = true
        this.$router.push('/login')
      }
    },
    // handleLoginClose() {
    //     this.showLogin = false
    //     if (this.isLogin) {
    //         if (this.loginType == 'personal') {
    //             this.$router.push({
    //                 path: '/personalCenter'
    //             })
    //         } else if (this.loginType == 'college') {
    //             this.$store.commit('setHomeScrollTop', 0)
    //             this.$store.commit('clearKeepAlive', [])
    //             this.$router.push({
    //                 path: '/overSelectCollege'
    //             })
    //         }
    //     }
    // },
    collegeClose () {
      this.$nextTick(() => {
        this.$router.replace({
          path: '/',
          query: {
            college_id: -1
          }
        })
      })
    },
    handleExpert () {
      this.$store.commit('setHomeScrollTop', 0)
      this.$store.commit('clearKeepAlive', [])
      console.log('全部专家')
      this.$router.push({
        path: '/expertList',
        query: {
          college_id: this.collegeID,
          college_name: this.$route.query.collegeName
        }
      })
    },
    handleClick (data) {
      this.$store.commit('setHomeScrollTop', 0)
      this.$store.commit('clearKeepAlive', [])
      this.$router.push({
        path: '/doctorMainPage',
        query: {
          doctor_id: data
        }
      })
    },
    handleSearch () {
      this.$store.commit('home/setSearchText', '')
      this.$store.commit('home/setSearchTabIndex', '')
      this.$store.commit('setHomeScrollTop', 0)
      this.$store.commit('clearKeepAlive', [])
      this.$router.push({
        path: '/search',
        query: {
          college_id: this.collegeID,
          college_name: this.$route.query.collegeName
        }
      })
    },
    async getData (type) {
      this.setWXShare()
      this.$showLoading()
      this.$store.dispatch('home/getBannerData')
      if (type != 'disease') {
        this.$store.dispatch('home/getDiseaselist').then(res => {
          if (res && res.length) {
            res = [{ disease_id: 0, disease_name: '推荐' }, ...res]
          }
          this.$store.commit('home/setDiseaselist', res)
        })
      }
      this.$store.dispatch('home/getAllCollege').then(res => {
        let { data } = res
        this.$store.commit('home/setAllCollege', data)
        this.$nextTick(() => {
          data.map(item => {
            if (item.college_id == this.collegeID) {
              this.collegeText = item.college_name
              this.downLoadLoadText = item.download_logo_title
              this.isDownLoadLoad = item.download_logo
              console.log(this.isDownLoadLoad, this.collegeID, item, 'DownLoadLoad')
            }
          })
          this.getExpertlist()
          this.$forceUpdate()
        })
      })
      this.tabLoading = true
      this.$store
        .dispatch('home/getMenuicon', {
          college_id: this.collegeID,
          disease_id: this.diseaseID
        })
        .then(res => {
          let { data } = res
          this.$nextTick(() => {
            this.tabs = data

            setTimeout(() => {
              console.log('menuDataContainer', this.menuDataContainer)
            }, 1000)
            this.tabLoading = false
          })
        })
      // 首页四个模块数据
      this.$store
        .dispatch('home/contentlist')
        .then(res => {
          const { meeting, surgical, production, information } = res
          this.surgicalList = surgical
          this.meetingList = meeting
          this.productionList = production
          this.informationList = information
          this.$nextTick(() => {
            if (this.isInApp) {
              this.searchTop = document
                .querySelector('.navbar')
                .getBoundingClientRect().height
            } else {
              this.searchTop = document
                .querySelector('.search')
                .getBoundingClientRect().top
            }
            if (this.isDownLoadLoad == 1) {
              this.AppTipsHeight = this.showHeaderAppTips
                ? document.querySelector('.app_tips').getBoundingClientRect().height
                : 0
            } else {
              this.AppTipsHeight = 0
            }

            this.$hideLoading()
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    setWXShare (title = '') {
      let imgUrl = 'https://' + location.host + '/icon/ydkc.png'
      title = title ? title : '医谱'
      if (!this.isMain) {
        imgUrl = 'https://' + location.host + '/icon/xin.png'
        // title = '心血管远程教育学院'
        document.title = ''
        //  title
      }
      this.$wx.share({
        imgUrl,
        title,
        desc: '医谱 专业的医生学术交流平台'
      })
    },
    toPersonalCenter () {
      this.$store.commit('setHomeScrollTop', 0)
      this.$store.commit('clearKeepAlive', [])
      this.$router.push('/personalCenter')
    },
    //点击跳转广告图
    bannerClick (id) {
      this.$store.commit('setHomeScrollTop', 0)
      this.$store.commit('clearKeepAlive', [])
      let data = {}
      this.bannerData.map((item, index) => {
        if (index == id) {
          data = item
        }
      })
      const { ads_type, item_id, is_big_meeting, ads_link } = data
      if (ads_type == 1) {
        this.$router.push({
          path: is_big_meeting ? 'meetingHome' : 'meetingDetail',
          query: {
            meeting_id: item_id
          }
        })
      } else if (ads_type == 2) {
        this.$router.push({
          path: 'meetingPlaybackDetail',
          query: {
            meeting_id: item_id
          }
        })
      } else if (ads_type == 3) {
        // if (this.isInApp) {
        //     this.$nativeBridge.sendMessage(
        //         new NativeMessagePush({
        //             pageName: 'newWeb',
        //             params: {
        //                 url: ads_link
        //             }
        //         })
        //     )
        // } else {
        if (ads_link) location.href = ads_link
        // }
      }
    },
    // 点击切换疾病
    // changeSelectedDisease(item, index) {
    //     // if (!this.isInApp) {
    //     const newUrl =
    //         this.$route.path +
    //         `?college_id=${this.collegeID}&collegeName=${this.collegeText}&disease_id=${item.disease_id}`
    //     window.history.replaceState('', '', newUrl)
    //     // }

    //     this.$store.commit('home/setDiseaseID', item.disease_id)
    //     this.diseaseActive = item.disease_id
    //     this.getData('Disease')
    //     this.expertPage = 1
    //     // this.showSelectChannel = false
    //     this.$nextTick(() => {
    //         this.tabs = []
    //         this.back_to_top()
    //         this.$forceUpdate()
    //     })
    // },
    // 右侧菜单中点击
    changeSelectedCollege (item, index) {
      // if (!this.isInApp) {
      const newUrl =
        this.$route.path +
        `?college_id=${item.college_id}&collegeName=${item.college_name}&disease_id=0`
      window.history.replaceState('', '', newUrl)
      // }

      this.collegeText = item.college_name
      this.$store.commit('home/setCollegeID', item.college_id)
      this.$store.commit('home/setDiseaseID', 0)
      this.diseaseActive = 0
      this.getData('College')
      this.expertPage = 1
      this.showPopupBox = false
      this.$nextTick(() => {
        this.tabs = []
        this.back_to_top()
        this.$forceUpdate()
      })
    },
    // joinMeeting() {
    //     this.$router.push('/videoConferencing')
    //     this.$nativeBridge.sendMessage(
    //         new NativeMessagePush({
    //             pageName: 'meeting',
    //         })
    //     )
    //     // this.$store.commit('setTabbarSelectedIndex', 1)
    //     // this.$store.commit('setTabbarSelectedIndex', 1)
    //     // this.$nativeBridge.sendMessage(
    //     //     new NativeMessagePush({
    //     //         pageName: 'meeting'
    //     //     })
    //     // )
    // },
    getBacklogCount () {
      Request.post(api.backlog.waitCount).then(res => {
        const { data } = res
        this.backlogCount = data.count
        if (parseInt(data.count) > 0) this.showBacklogDialog = true
      })
    },
    handleBacklog () {
      this.$router.push('/backlog')
    },
    //切换学科
    toSubjectFocus () {
      if (this.isInApp) {
        this.$nativeBridge.sendMessage(
          new NativeMessageNotice({
            type: 'toSubjectFocus'
          })
        )
      } else {
        this.showPopupBox = true
      }
    },
    goBack () {
      // this.$router.go(-1)else {
      if (this.isInApp) {
        this.$nativeBridge.sendMessage(
          new NativeMessageNotice({
            type: 'goBack'
          })
        )
      } else {
        if (!this.canGoback) {
          this.$router.push('/')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
}
</script>

<style>
.swiper-container {
  z-index: 0;
}
</style>

<style scoped lang="scss">
@import "@styles/variables.scss";
::-webkit-scrollbar {
  display: none;
}
.loading {
  // width: 100%;
  // min-height: 4rem;
  position: relative;
  z-index: 999 !important;
}
.pc-right {
  // right: 600px;
}

.scroll_to_top {
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  right: 0.7rem;
  bottom: 2rem;
  z-index: 3;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.join-meeting {
  width: 2.8rem;
  height: 2.8rem;
  position: fixed;
  right: 0;
  bottom: 10rem;
  z-index: 3;
  img {
    width: 2.8rem;
    height: 2.8rem;
  }
}
.no_banner {
  width: 100%;
  // min-height: 9rem;
  background: linear-gradient(
    180deg,
    #0089ff 0%,
    #0089ff 20%,
    rgba(0, 137, 255, 0) 100%
  ) !important;
}
.home_navbar {
  width: 100%;
  // min-height: 7rem;
  background: linear-gradient(
    180deg,
    #0089ff 0%,
    #0089ff 30%,
    #fff 80%,
    #fff 100%
  );
}
.navbar {
  position: relative;
  width: 100%;
  height: 2.01rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.69rem 0 0.69rem;
  background: #0089ff;
  .navbar_left {
    position: relative;
    width: 2.96rem;
    text-align: left;
    display: flex;
    align-items: center;
    img {
      width: 1rem;
      height: 1rem;
      // border-radius: 100%;
    }
    .login_img {
      width: 1.14rem;
      height: 1.14rem;
      border-radius: 100%;
    }
    .new-tip {
      position: absolute;
      right: 1.5rem;
      top: -0.2rem;
      width: 0.64rem;
      height: 0.64rem;
      text-align: center;
      line-height: 0.64rem;
      border-radius: 100%;
      background: #f5222d;
      font-size: $fsxs;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .navbar_center {
    flex: 1;
    font-size: 0.73rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
  .navbar_right {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    width: 2.96rem;
    cursor: pointer;
    font-size: $fslg;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: right;
    img {
      margin-left: 0.1rem;
      width: 0.18rem;
      height: 0.18rem;
    }
  }
}
.yipu ::v-deep {
  .van-tabs__nav {
    background: none;
  }
}
.banner {
  width: 100%;
  // background: #fff;
  // height: 7.69rem;
  // height: 9rem !important;
}
.swiper-one {
  position: relative;
  height: 5.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  p {
    width: 1.4rem;
    height: 100%;
    transform: scale(0.9);
    background: #f1f1f5;
    border-radius: 8px;
  }
  p:nth-child(1) {
    margin-left: -0.2rem;
  }
  p:nth-child(3) {
    margin-right: -0.2rem;
  }

  .swiper-one-img {
    border-radius: 8px;
    /* width: 100%; */
    height: 100%;
    overflow: hidden;
    img {
      width: 13rem;
      // height: 100%;

      box-shadow: 0px 4px 8px 0px rgba(206, 206, 206, 0.4),
        0px 4px 16px 0px rgba(206, 206, 206, 0.3);
    }
  }
}
.search {
  cursor: pointer;
  width: 100%;
  // height: 1.8rem;
  margin-bottom: 0.43rem;
  padding: 0 0.69rem;
  // background: #0089ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.37rem;
    background: #fff;
    border-radius: 0.15rem;
    box-sizing: border-box;
    color: #cecece;
    font-size: $fsxl;
    box-shadow: 0rem 0.14rem 0.27rem 0rem rgba(0, 122, 227, 0.4);
    border-radius: 0.78rem;
    .search_text {
      margin-left: 0.1rem;
    }
    img {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .search_person {
    margin-left: 0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.own_sticky_visibility {
  visibility: hidden;
  height: 3.43rem;
}
.own_sticky {
  position: fixed;
  z-index: 999;
  top: 0;
  background: #0089ff;
}
.sticky_search {
  position: relative;
  padding: 0rem 0.69rem;
  width: 100%;
  height: 2.01rem;
  background: #0089ff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sticky_left {
    position: relative;
    // width: 2.28rem;
    display: flex;
    align-items: center;
    margin-right: 0.66rem;
    text-align: center;
    img {
      width: 1rem;
      height: 1rem;
      // border-radius: 100%;
    }
    .login_img {
      width: 1.14rem;
      height: 1.14rem;
      border-radius: 100%;
    }
    .new-tip {
      position: absolute;
      right: -0.3rem;
      top: -0.2rem;
      width: 0.64rem;
      height: 0.64rem;
      text-align: center;
      line-height: 0.64rem;
      border-radius: 100%;
      background: #f5222d;
      font-size: $fsxs;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .sticky_center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.14rem;
    background: #fff;
    box-sizing: border-box;
    color: #cecece;
    font-size: $fsxl;
    box-shadow: 0rem 0.14rem 0.27rem 0rem rgba(0, 122, 227, 0.4);
    border-radius: 0.78rem;
    overflow: hidden;
    padding: 0 0.3rem;
    .search_text {
      // display: inline-block;
      // width: 60%;
    }
    img {
      margin-right: 0.2rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .sticky_right {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-left: 1.05rem;
    // width: 3rem;
    cursor: pointer;
    font-size: $fslg;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: right;
    img {
      margin-left: 0.1rem;
      width: 0.18rem;
      height: 0.18rem;
    }
  }
}
.yipu {
  display: flex;
  align-items: center;
  width: 100%;
  // background: #fff;
  overflow: hidden;
  margin: 0 auto 0.43rem auto;
}

.menu_tab {
  width: 100%;
  margin: 0.46rem 0 0rem 0;
  padding: 0 0.69rem;
  display: flex;
  flex-wrap: wrap;
  .menu_item {
    position: relative;
    width: 20%;
    min-height: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-left: 0.28rem;
    margin-bottom: 0.21rem;
    text-align: center;
    // &:nth-child(5n + 1) {
    //     margin-left: 0;
    // }
    .menu_logo {
      width: 2.34rem;
      height: 2.34rem;
      img {
        width: 2.34rem;
        height: 2.34rem;
      }
    }

    span {
      // margin-top: 0.2rem;
      font-size: 0.52rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .new_tip {
    position: absolute;
    top: 0.15rem;
    right: 0.55rem;
    width: 0.73rem;
    height: 0.37rem;
    text-align: center;
    line-height: 0.4rem;
    img {
      width: 0.73rem;
      height: 0.37rem;
    }
  }
}

.swiper-pagination {
  ::v-deep .swiper-pagination-bullet {
    width: 0.26rem;
    height: 0.26rem;
    background: #cecece;
    border-radius: 0.13rem;
    opacity: 1;
    // bottom: 0;
  }

  ::v-deep .swiper-pagination-bullet-active {
    width: 0.8rem;
    height: 0.26rem;
    background: #0089ff;
    border-radius: 0.13rem;
    opacity: 1;
    // bottom: 0;
  }
}

::v-deep .swiper-pagination-bullets {
  bottom: 0;
}
.HomeExpertList {
  background: #fff;
  padding-left: 3%;
  box-sizing: border-box;
  .expert-title {
    height: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:nth-child(1) {
      color: #222222;
      font-size: $fsxl;
      font-weight: 600;
    }
    span:nth-child(2) {
      padding-right: 3%;
      cursor: pointer;
      color: #888888;
      font-size: 0.51rem;
      display: flex;
      align-items: center;
    }
  }
}
.line-1px {
  width: 100%;
  height: 1px;
  background: #ececec;
}
.tab-item {
  flex: 1;
  height: 1.5rem;
  margin-right: 0px;
  text-align: center;
  line-height: 1.5rem;
  color: #888888;
  cursor: pointer;
}
.home-tab {
  height: 1.5rem;
  background: #fff;
  // border-bottom: 1px solid #ececec;
}

.home-tab-text {
  font-size: 0.64rem;
}

.home-tab-actived {
  color: #222222;
  background: url(~@images/home/zhuan_select.png) no-repeat center bottom;
  background-size: 0.8rem 0.13rem;
  font-weight: bold;
  p {
    font-size: $fsxl !important;
  }
}
.college-tab-text {
  font-size: $fslg !important;
}

.line {
  width: 100%;
  height: 0.24rem;
  margin: 0 auto;
  background-color: #f1f1f5;
}
.scroll-main {
  // height: calc(100vh - 4.75rem);
  flex: 1;
  height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.home-main-wrap {
  width: 100%;
  flex: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.home-main-content {
  margin-top: 0.23rem;
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: #f6f7f9;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.nav-bar-personal-center {
  font-size: 0.6rem;
  color: #6194fe;
  font-weight: 400;
  cursor: pointer;
}

.nav-bar-menu {
  width: 0.26rem;
  height: 0.55rem;
  cursor: pointer;
}
@media screen and (min-width: 750px) {
  .collegeStyle {
    width: calc(100% - 2.24rem);
  }
  .menu_item {
    // margin-left: 0.47rem !important;
    &:nth-child(5n + 1) {
      // margin-left: 0 !important;
    }
  }
}
.popup-box ::v-deep {
  position: absolute;
  top: 0rem;
}

.clipboard-one-login ::v-deep {
  position: absolute;
}

.menu-swiper {
  padding-bottom: 0.5rem;
}

.backlog-container {
  position: absolute;
  bottom: 2.47rem;
  left: 0.64rem;
  right: 0.64rem;
  background: linear-gradient(
    360deg,
    rgba(97, 118, 140, 0.95) 0%,
    rgba(49, 64, 79, 0.95) 100%
  );
  box-shadow: 0rem 0.13rem 0.34rem 0rem rgba(49, 64, 79, 0.4);
  border-radius: 0.17rem;
  height: 2.05rem;
  display: flex;
  align-items: center;
  padding: 0 0.43rem;

  > img {
    position: absolute;
    width: 0.68rem;
    height: 0.68rem;
    left: -0.34rem;
    top: -0.34rem;
  }

  > span {
    flex: 1;
    font-size: 0.6rem;
    font-weight: 400;
    color: #ffffff;
  }

  > .handle-button {
    width: 3.67rem;
    height: 1.37rem;
    background: #0089ff;
    border-radius: 0.68rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > span {
      font-size: 0.6rem;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.goback {
  width: 0.85rem !important;
  height: 0.85rem !important;
}
// <div class="backlog-container">
//     <img src="" alt="">
//     <span>提示：您有 5 条待办任务</span>
//     <div class="handle-button">
//         <span>立即处理</span>
//     </div>
// </div>
</style>
